import React, { useState, useEffect } from "react";
import DD from "../images/DDhome.png";
import loaderGif from "../images/loader.gif";
import pinIcon from "../images/pinicon.png";
import benjiImage from "../images/benji.png";
import rcpt from "../images/rcpt.png";
import beat from "../images/beat.png";

const Loader = () => (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    }}
  >
    <img className="w-full max-w-[120px]" src={loaderGif} alt="Loading" />
  </div>
);
const slidesData = [
  {
    title: "Benji",
    content: "Your digital bear friend.",
    image: benjiImage,
    location: "USA",
    url: "https://benjithebear.com/",
  },
  {
    title: "Rceipt",
    content: "Digitalizing the receipt, one tap at a time.",
    image: rcpt,
    location: "USA",
    url: "http://www.rceipt.com/",
  },
  {
    title: "Beatmock",
    content:
      "Heart mimicking technology to aid in pulse rate and cardiovascular health.",
    image: beat,
    location: "Oslo-Norway",
    url: "http://beatmock.com/",
  },
];

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slidesData.length);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [currentSlide, isPaused]);

  const navigateToSlide = (index) => {
    setCurrentSlide(index);
    setIsPaused(true);
    setTimeout(() => setIsPaused(false), 5000);
  };

  const visibleSlidesIndexes = [
    currentSlide % slidesData.length,
    (currentSlide + 1) % slidesData.length,
    (currentSlide + 2) % slidesData.length,
  ];

  return (
    <>
      {isLoading && <Loader />}
      <div
        className={`flex flex-col justify-center md:justify-evenly items-center h-full ${
          isLoading ? "hidden" : ""
        }`}
      >
        <div className="w-full">
          <div className="grid grid-cols-10 w-full px-8 gap-4">
            <div
              className="hidden md:grid col-span-10 md:col-span-5 bg-contain bg-no-repeat bg-right max-h-[500px] h-full my-auto items-center content-center align-middle"
              style={{ backgroundImage: `url(${DD})` }}
            >
              <h1 className="text-6xl leading-[75px] pb-8">
                Innovation
                <br />
                Oddly enough
              </h1>
              <p className="max-w-[450px]">
                With everything being redone in just a slightly different,
                dramatic way and calling it "new", why don't we at least make it
                better than it was previously? And by a lot immediately?
              </p>
            </div>

            <div
              className="grid md:hidden col-span-10 h-full my-auto w-full bg-no-repeat bg-right items-start z-20"
              style={{ backgroundImage: `url(${DD})`, backgroundSize: "150px" }}
            >
              <h1 className="text-2xl leading-[30px] pb-2">
                Innovation
                <br />
                Oddly enough
              </h1>
              <p className="text-[12px] max-w-[150px]">
                With everything being redone in just a slightly different,
                dramatic way and calling it "new", why don't we at least make it
                better than it was previously? And by a lot.
              </p>
            </div>

            <div className="grid col-span-7 md:col-span-2 items-start content-start mt-4 md:pl-8">
              <h2 className="block md:hidden mb-4">Operations</h2>
              <div className=" shadow-lg rounded-lg p-8 mb-12 info">
                <h4 className="pb-2">
                  {slidesData[visibleSlidesIndexes[0]].title}
                </h4>
                <p className="max-w-[450px]">
                  {slidesData[visibleSlidesIndexes[0]].content}
                </p>
              </div>
              <h2 className="hidden md:block">Operations</h2>
            </div>
            <div className="grid col-span-10 justify-end mx-auto md:col-span-3 mb-12 md:pl-8">
              {visibleSlidesIndexes.map((index) => (
                <div
                  key={index}
                  onClick={() => navigateToSlide(index)}
                  className={`shadow-lg rounded-lg px-8 py-12 slide relative ${
                    index === currentSlide ? "slide-active" : ""
                  }`}
                  style={{
                    marginBottom: "20px",
                    cursor: "pointer",
                    transition: "transform 1s, opacity 1s",
                  }}
                >
                  <img
                    className="py-4"
                    src={slidesData[index].image}
                    alt={`${slidesData[index].title} Slide image`}
                    style={{ maxWidth: "50%", alignSelf: "center" }}
                  />
                  <a
                    href={slidesData[index].url}
                    target="_blank"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      backgroundColor: "#3754ed",
                      color: "white",
                      padding: "8px 20px",
                      fontSize: "12px",
                      borderRadius: "5px",
                      textDecoration: "none",
                    }}
                  >
                    View Project
                  </a>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      right: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={pinIcon}
                      alt="Location icon"
                      style={{ marginRight: "5px", maxWidth: "12px" }}
                    />
                    <span>{slidesData[index].location}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
