import React, { useEffect, useState } from 'react';
import { sanityClient } from '../sanityClient';
import { Link } from 'react-router-dom';
import imageUrlBuilder from '@sanity/image-url';
import Slider from 'react-slick'; // Importing react-slick
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const builder = imageUrlBuilder(sanityClient);

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} z-10`}
      onClick={onClick}
    >
      <svg className="h-6 w-6 text-black mt-[-40px]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} z-10`}
     
      onClick={onClick}
    >
      <svg className="h-6 w-6 text-black mt-[-40px]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
      </svg>
    </div>
  );
}

function urlFor(source) {
  return builder.image(source);
}

const News = () => {
  const fetchPosts = async () => {
    const query = `*[_type == "post"] | order(publishedAt desc) {
      title,
      slug,
      mainImage,
      publishedAt,
      _id,
      featured
    }`;
    const posts = await sanityClient.fetch(query);
    return posts;
  };

  const [featuredPost, setFeaturedPost] = useState(null);
  const [gridPosts, setGridPosts] = useState([]);

  useEffect(() => {
    fetchPosts().then(data => {
      console.log("Fetched posts data:", data); 
      const featuredPost = data.find(post => post.featured) || data[0]; 
      const grid = data.filter(post => post !== featuredPost);
      setFeaturedPost(featuredPost);
      setGridPosts(grid);
    });
  }, []);
  

  // Slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow  />,
    prevArrow: <SamplePrevArrow  />,responsive: [
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full max-w-7xl mx-auto p-8">
      <div className="py-2 border-b border-black">
        <h1>News</h1>
      </div>
      {featuredPost && (
        <div className="mb-8 mt-8 w-full">
          <Link to={`/post/${featuredPost.slug.current}`} className="no-underline hover:underline">
            <img src={urlFor(featuredPost.mainImage).url()} alt={featuredPost.title} className="w-full md:aspect-[6/3] rounded-xl border object-cover  aspect-square" />
            <h1 className="title mt-4">{featuredPost.title}</h1>
          </Link>
          <h5 className="subtitle">{new Date(featuredPost.publishedAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</h5>
        </div>
      )}
       <div className="py-2 border-b border-black mb-4">
        <h1>Recent Blog Posts</h1>
      </div>
      <div className='mb-4 md:mb-8'>
      <Slider {...sliderSettings}>
      {gridPosts.map((post) => (
           <div key={post._id} className='px-2 md:px-4 my-4'>
           <div className='flex border rounded-xl h-full w-full aspect-square bg-gray-100 justify-center items-end '>
            <div className='p-4'> 
                <h5 className="subtitle">{new Date(post.publishedAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</h5> 
                <Link to={`/post/${post.slug.current}`} className="">   
                <h3 className='text-4xl py-2'>{post.title}</h3>
                <h5 className='font-normal border-b-2 w-max pr-4 border-gray-300 hover:border-black'>Read More</h5>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      </div>
    </div>
  );
};

export default News;
