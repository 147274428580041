import React, {useState} from "react";
import { NavLink } from 'react-router-dom'
import MainLogo from '../images/logo.png';
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import axios from 'axios';


function Navbar() {
  const [nav, setNav] = useState(true)
  const handleNav = () => {
    setNav(!nav);
  }

  return (
    <div className="flex justify-between items-center py-4 px-8 mx-auto border-b border-[#999999]">
      <a href="/"><img className="h-[20px] z-20" src={MainLogo} alt="logo" /></a>

    
      <div className={!nav ? 'absolute right-0 md:px-4 flex-col z-10 translate-x-[-25%]  ease-in duration-200  ' : ' fixed right-0 translate-x-[100%] ease-in duration-300 z-10 overflow-hidden'}>
         <ul className="flex">
            <li className="px-2 text-bold"><NavLink to="/">Home</NavLink></li>
            <li className="px-2 text-bold"><NavLink to="/news">News</NavLink></li>
            <li className="px-2 text-bold"><NavLink to="/contact">Contact</NavLink></li>
         </ul>
      </div>
      <div className="z-20 cursor-pointer" onClick={handleNav} >
      {!nav ? <AiOutlineClose  size={20} /> : <AiOutlineMenu  size={20} />}
    </div>
    </div>
  
  );
}

export default Navbar;
