import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { sanityClient } from '../sanityClient'; 
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from '@sanity/block-content-to-react';


const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const PostDetail = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    sanityClient.fetch(`*[slug.current == "${slug}"]{
      title,
      mainImage,
      body,
      publishedAt,
    }`).then((data) => setPost(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!post) return <div>Loading...</div>;

  return (
    <div className="w-full max-w-7xl mx-auto p-8">
      <div className="py-2 border-b border-black mb-8">
        <h1>News</h1>
      </div>
      <img src={urlFor(post.mainImage).url()} alt={post.title} className="w-full" />
      <h1 className='pt-4 title'>{post.title}</h1>
      <h5 className="subtitle pb-4">{new Date(post.publishedAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</h5>
      <BlockContent
        blocks={post.body}
        projectId={sanityClient.config().projectId}
        dataset={sanityClient.config().dataset}
      />
    </div>
  );
};
  
  export default PostDetail;