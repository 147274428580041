import React, { useState, useEffect } from "react";

const Cookie = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem("cookieConsent");
    if (!cookieConsent) {
      setTimeout(() => {
        setIsVisible(true);
        setShouldAnimate(true);
      }, 5000); // 5-second delay
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setIsVisible(false);
  };

  const handleDecline = () => {
    console.log(
      "User declined cookies. Implement corresponding behavior here."
    );
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div
      className={`fixed bottom-0 left-0 right-0 bg-black text-white py-8 px-4 md:px-[8%] flex flex-col md:flex-row justify-between items-center z-50 transition-transform duration-500 ${
        shouldAnimate ? "slide-up-animation" : ""
      }`}
    >
      <p className="text-sm text-center md:text-left md:max-w-[80%] mb-4 md:mb-0">
        This website uses cookies to ensure you get the best experience on our
        website. Cookies are used to store user preferences, secure user data,
        and provide anonymized tracking data to third party applications. As a
        part of our commitment to your privacy, we give you the power to choose
        which cookies you're comfortable with.
      </p>
      <div className="flex flex-row md:flex-col items-center md:items-end">
        <button
          onClick={handleAccept}
          className="bg-[#3754ed] mr-10 md:mr-0 text-white py-2 px-6 md:px-12 my-1 text-sm rounded focus:outline-none hover:ring-2 hover:ring-white focus:ring-opacity-50"
        >
          Accept
        </button>
        <button
          onClick={handleDecline}
          className="bg-[#65caf9] text-black py-2 px-6 md:px-12 my-1 text-sm rounded focus:outline-none hover:ring-2 hover:ring-white focus:ring-opacity-50"
        >
          Decline
        </button>
      </div>
    </div>
  );
};

export default Cookie;
